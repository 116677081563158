import React from 'react';
import styles from './style.module.scss';
import { testIds } from 'constants/test-constants';

interface Props {
  description: string;
}

const ImageDescription = ({ description }: Props) => {
  return (
    <div className={styles.component} data-testid={testIds.imageDescription} >
      <p className={styles.description}>{description}</p>
    </div>
  );
};

export default ImageDescription;