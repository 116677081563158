import React, { PropsWithChildren } from 'react';
import styles from './style.module.scss';

type AspectRatioProps = PropsWithChildren<{
  /** The aspect ratio of the content in the format of width:height e.g. "16:9" */
  ratio: string;
  id?: string;
}>;

export default function AspectRatio({ ratio, children, id }: AspectRatioProps) {
  const [width, height] = ratio.split(':').map(value => parseInt(value));
  const paddingTop = `${height / width * 100}%`;

  return (
    <div className={styles.component} style={{ paddingTop }} id={id}>
      <div className={styles.wrapper}>{children}</div>
    </div>
  );
}
